'use strict';

(function($){
    var searcher = {

        init: function() {
            this.asideSearch = $('#aside-search');
            this.lineaSelect = this.asideSearch.find('select#linea');
            this.archivoSelect = this.asideSearch.find('select#tipo_archivo');
            this.documentoSelect = this.asideSearch.find('select#tipo_documento');
            this.familiaSelect = this.asideSearch.find('select#familia');
            this.skuSelect = this.asideSearch.find('select#sku');
            this.instalacionSelect = this.asideSearch.find('select#instalacion');
            this.form = $('form#search');
            this.combos();
            this.pagination();
            this.resetPage();
            this.resetForm();
            this.fixedDirectory();
            return false;
        },
        combos : function ()
        {
            if(typeof this.asideSearch.html() !== 'undefined')
            {
                this.fillCombos(this.lineaSelect,this.archivoSelect);
                this.fillCombos(this.lineaSelect,this.documentoSelect,this.archivoSelect);
                this.fillCombos(this.archivoSelect,this.documentoSelect,this.lineaSelect);
                this.fillCombos(this.documentoSelect,this.familiaSelect,this.lineaSelect);
                this.fillCombos(this.documentoSelect,this.instalacionSelect);
                this.fillCombos(this.familiaSelect,this.skuSelect,this.documentoSelect);

                this.lineaSelect.change(function ()
                {
                    searcher.fillCombos(searcher.lineaSelect,searcher.archivoSelect);
                    searcher.fillCombos(searcher.lineaSelect,searcher.documentoSelect,searcher.archivoSelect);
                    searcher.fillCombos(searcher.documentoSelect,searcher.familiaSelect,searcher.lineaSelect);
                })

                this.archivoSelect.change(function ()
                {
                    searcher.fillCombos(searcher.archivoSelect,searcher.documentoSelect,searcher.lineaSelect);
                });

                this.documentoSelect.change(function ()
                {
                    searcher.fillCombos(searcher.documentoSelect,searcher.familiaSelect,searcher.lineaSelect);
                    searcher.fillCombos(searcher.documentoSelect,searcher.instalacionSelect);
                });

                this.familiaSelect.change(function ()
                {
                    searcher.fillCombos(searcher.familiaSelect,searcher.skuSelect,searcher.documentoSelect);
                });
            }
        },
        fillCombos : function(parentSelect,childrenSelect,extraParentSelect)
        {
            var parentValue = (parentSelect.val() !== '') ? parentSelect.val() : 0;
            var childrenValue = (childrenSelect.val() !== '') ? childrenSelect.val() : 0;
            var extraParentValue = (typeof extraParentSelect !== 'undefined' && typeof  extraParentSelect.val() !== 'undefined') ? extraParentSelect.val() : 0;
            var ajaxFunction = childrenSelect.attr('id') + '_por_' + parentSelect.attr('id');

            $.ajax(
                {
                    url : site_url + '/'  + ajaxFunction,
                    data : {parentValue : parentValue, childrenValue : childrenValue, extraParentValue : extraParentValue},
                    dataType : 'json',
                    method : 'POST',
                    success : function (data)
                    {
                        childrenSelect.find('option').each(function ()
                        {
                            if($(this).attr('value') !== '' && parseInt($(this).attr('value')) !== 0) $(this).remove();
                        })

                        if(!data.noData)
                        {
                            for(var d in data)
                            {
                                childrenSelect.append('<option value="' + data[d]['value'] + '"' + ((childrenValue && childrenValue == data[d]['value']) ? ' selected' : '') +  '>' + data[d]['texto'] + '</option>');
                            }
                            if(childrenValue) childrenSelect.val(childrenValue);
                        }
                    }
                }
            )
        },
        pagination : function ()
        {
            $('.pagination.with-search').find('.page-link').click(function (e)
            {
                e.preventDefault();
                if(!$(this).parent('li').hasClass('active'))
                {
                    $('input#hidden-pag').val($(this).data('page'));
                    searcher.form.submit();
                }
                else $(window).scrollTop(0);
            })
        },
        resetPage : function (){
            //Para evitar que se mantenga la pagina en la que estamos al realizar una nueva busqueda
            this.form.find('button[type="submit"]').on('click',function (e){
                e.preventDefault();
                $('input#hidden-pag').val(1);
                searcher.form.submit();
            })
        },
        resetForm : function (){
            //Para evitar que se mantenga la pagina en la que estamos al realizar una nueva busqueda
            this.form.find('button[type="reset"]').on('click',function (e){
                e.preventDefault();
                searcher.form.find('input[type="text"],select').val('');
                searcher.form.find('input[type="checkbox"]').prop('checked',false);
            })
        },
        fixedDirectory : function ()
        {
            var inputFixer = $('input#directorio_actual');
            this.doFixedDirectory(inputFixer);
            inputFixer.click(function ()
            {
                searcher.doFixedDirectory($(this));
            })
        },
        doFixedDirectory : function (obj)
        {
            var selectLinea = $('select#linea');
            var selectFileType = $('select#tipo_archivo');
            var selectDocType = $('select#tipo_documento');
            var selectFamily = $('select#familia');
            var selectInstalation = $('select#instalacion');
            var selectSku = $('select#sku');
            var hiddenLineaVal = $('#hidden-idlinea').val();
            var hiddenFileTypeVal = $('#hidden-idarchivo').val();
            var hiddenDocTypeVal = $('#hidden-iddocumento').val();
            var hiddenSubdocTypeVal = $('#hidden-idsubdocumento').val();
            var hiddenSkuVal = $('#hidden-idsku').val();
            if(obj.prop('checked'))
            {
                searcher.form.attr('action',$('#hidden-current-url').val());
                if(hiddenLineaVal !== '' && parseInt(hiddenLineaVal) !== 0)
                {
                    selectLinea.val(hiddenLineaVal);
                    selectLinea.find('option').each(function ()
                    {
                        if(parseInt($(this).attr('value')) !== parseInt(hiddenLineaVal))
                        {
                            $(this).prop('disabled',true);
                        }
                    })
                }
                if(hiddenFileTypeVal !== '' && parseInt(hiddenFileTypeVal) !== 0)
                {
                    selectFileType.val(hiddenFileTypeVal);
                    selectFileType.find('option').each(function ()
                    {
                        if(parseInt($(this).attr('value')) !== parseInt(hiddenFileTypeVal))
                        {
                            $(this).prop('disabled',true);
                        }
                    })
                }
                if(hiddenDocTypeVal !== '' && parseInt(hiddenDocTypeVal) !== 0)
                {
                    selectDocType.val(hiddenDocTypeVal);
                    selectDocType.find('option').each(function ()
                    {
                        if(parseInt($(this).attr('value')) !== parseInt(hiddenDocTypeVal))
                        {
                            $(this).prop('disabled',true);
                        }
                    })
                }
                if(hiddenSubdocTypeVal !== '' && parseInt(hiddenSubdocTypeVal) !== 0 && hiddenDocTypeVal !== '' && parseInt(hiddenDocTypeVal) !== 0)
                {
                    //En este caso segun el tipo de documento marcamos la familia o las instalaciones
                    if(parseInt(hiddenDocTypeVal) === 4) var selectSubdoc = selectFamily;
                    if(parseInt(hiddenDocTypeVal) === 5) var selectSubdoc = selectInstalation;

                    if(typeof selectSubdoc !== 'undefined')
                    {
                        selectSubdoc.val(hiddenSubdocTypeVal);
                        selectSubdoc.find('option').each(function ()
                        {
                            if(parseInt($(this).attr('value')) !== parseInt(hiddenSubdocTypeVal))
                            {
                                $(this).prop('disabled',true);
                            }
                        })
                    }
                }

                if(hiddenSkuVal !== '' && parseInt(hiddenSkuVal) !== 0)
                {
                    selectSku.val(hiddenSkuVal);
                    selectSku.find('option').each(function ()
                    {
                        if(parseInt($(this).attr('value')) !== parseInt(hiddenSkuVal))
                        {
                            $(this).prop('disabled',true);
                        }
                    })
                }
            }
            else
            {
                searcher.form.attr('action',site_url);
                searcher.form.find('select').find('option').each(function ()
                {
                    $(this).prop('disabled',false);
                })
            }
        }
    };

    $(function(){
        searcher.init()
    });
})(jQuery);