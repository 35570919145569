'use strict';

(function($){
  var app = {

    init: function() {
      this.menu();
      this.cookiesAccept();
      this.cookiesConfig();
      this.lazyframe();
      this.popoverShow();
      return false;
    },
    menu: function() {
      var searchBtn = document.getElementById('search-mobile'),
      searchBtnClose = document.getElementById('btn-close'),
      body = document.getElementsByTagName('body'),
      backdrop = document.getElementsByClassName('backdrop')[0]
      if(searchBtn) {
        searchBtn.addEventListener('click', function() {
          body[0].classList.toggle('opened');
        })
      }
      if(searchBtnClose) {
        searchBtnClose.addEventListener('click', function() {
          body[0].classList.remove('opened');
        })
      }
      if(backdrop) {
        backdrop.addEventListener('click', function() {
          body[0].classList.remove('opened');
        })
      }      
    },
    cookiesAccept: function () {
      var msgCookies = $('#cookies-msg');
      var btnAccept = $('.btn-cookies-accept');
      btnAccept.click(function (e){
        e.preventDefault();
        $.cookie(keyAnalyticCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyMarketingCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyRrssCookies, '1', { expires: 7, path: '/' });
        msgCookies.slideUp();
      })
    },
    cookiesConfig: function (){
      var configContainer = $('.cookies-configuration-block');
      var msgCookies = $('#cookies-msg');

      if(typeof configContainer.html() !== 'undefined')
      {
        var checkAll = configContainer.find('input#cookies-todas');
        var checkAnalytics = configContainer.find('input#cookies-analiticas');
        var checkMarketing = configContainer.find('input#cookies-marketing');
        var checkRrss = configContainer.find('input#cookies-rrss');
        var buttonSave = configContainer.find('.btn-save-config');
        var msgSuccess = configContainer.find('#text-success');
        var msgError = configContainer.find('#text-error');

        checkAll.click(function ()
        {
          if($(this).prop('checked'))
          {
            checkAnalytics.prop('checked',true);
            checkMarketing.prop('checked',true);
            checkRrss.prop('checked',true);
          }
          else
          {
            checkAnalytics.prop('checked',false);
            checkMarketing.prop('checked',false);
            checkRrss.prop('checked',false);
          }
        })
        buttonSave.click(function ()
        {
          msgSuccess.addClass('d-none');
          msgError.addClass('d-none');
          $.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          if(typeof msgCookies.html() !== 'undefined') msgCookies.hide();
          msgSuccess.removeClass('d-none');
        })
      }
    },
    lazyframe: function () {
      lazyframe('.lazyframe', {
        apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
        debounce: 250,

        onAppend: (function (lazyframe) {
          return console.log(lazyframe.parentNode.classList.add("loaded"))
        })
      });
    },
    popoverShow : function (){
      $('[data-toggle="popover"]').popover(
          {
            trigger : 'hover'
          }
      )
    }
  };
  $(function(){
    app.init()
  });
})(jQuery);