'use strict';

(function($){
    var mediacenter = {

        init: function() {
            this.selectFile();
            this.removeFile();
            this.selectAllFiles();
            this.changeAllFiles();
            this.removeSelectedFiles();
            this.downloadZip();
            this.downloadCsv();
            this.downloadAllSelecteds();
            return false;
        },
        selectFile : function ()
        {
            $('input.select-file').click(function ()
            {
                var file = $(this).val();
                if(mediacenter.checkDownloadCenter() === false) {
                    if ($(this).prop('checked')) mediacenter.treatFile(file, 'add');
                    else mediacenter.treatFile(file, 'remove');
                }
                if(mediacenter.checkDownloadCenter() === true) mediacenter.controlButtonsDownload();
            })
        },
        selectAllFiles : function ()
        {
            var files = [];
            var actions = [];
            $('button#select-all').click(function (){
                $('input.select-file').each(function (){
                    $(this).prop('checked',true);
                    files.push($(this).val());
                    actions.push('add');
                })
                if(mediacenter.checkDownloadCenter() === false) mediacenter.treatFile(files,actions);
                if(mediacenter.checkDownloadCenter() === true) mediacenter.controlButtonsDownload();
            })
        },
        changeAllFiles : function ()
        {
            var files = [];
            var actions = [];
            $('button#change-all').click(function (){
                $('input.select-file').each(function (){
                    files.push($(this).val());
                    if($(this).prop('checked'))
                    {
                        actions.push('remove');
                        $(this).prop('checked',false)
                    }
                    else
                    {
                        actions.push('add');
                        $(this).prop('checked',true)
                    }
                })
                if(mediacenter.checkDownloadCenter() === false) mediacenter.treatFile(files,actions);
                if(mediacenter.checkDownloadCenter() === true) mediacenter.controlButtonsDownload();
            })
        },
        removeFile : function ()
        {
            $('a.delete-this').click(function (e)
            {
                e.preventDefault();
                var file = $(this).data('file');
                mediacenter.treatFile(file,'remove');
                if(mediacenter.checkDownloadCenter() === true)
                {
                    $(this).closest('li').remove();
                    if(mediacenter.checkHaveDowloads() === false) mediacenter.cleanInfoDownloads();
                }

            })
        },
        removeSelectedFiles : function ()
        {
            $('button#remove-selected').click(function ()
            {
                var files = [];
                var actions = [];
                $('input.select-file').each(function (){
                    if($(this).prop('checked'))
                    {
                        files.push($(this).val());
                        actions.push('remove');
                        if(mediacenter.checkDownloadCenter() === true) $(this).closest('li').remove();
                    }
                })
                mediacenter.treatFile(files,actions);
                if(mediacenter.checkDownloadCenter() === true && mediacenter.checkHaveDowloads() === false) mediacenter.cleanInfoDownloads();
            })
        },
        treatFile : function (file,action,goto)
        {
            $.ajax(
                {
                    url : site_url + '/treat_file',
                    data : {file : file, action : action},
                    dataType : 'json',
                    method : 'POST',
                    success : function (data)
                    {
                        var descargas = data.descargas;
                        if(!descargas) descargas = 0;
                        $('span.numero_descargas').html(descargas);
                        if(goto) window.location.href = goto;
                    }
                }
            )
        },
        checkDownloadCenter : function ()
        {
            return (typeof $('#download-center').html() !== 'undefined');
        },
        checkHaveDowloads : function ()
        {
            return ($('ul#files li').length > 0);
        },
        checkHaveDowloadsSelected : function ()
        {
            var haveDownloadsSelected = false;
            $('input.select-file').each(function (){
                if($(this).prop('checked'))
                {
                    haveDownloadsSelected = true;
                }
            })

            return haveDownloadsSelected;
        },
        cleanInfoDownloads : function ()
        {
            $('ul#files').remove();
            $('p.no-data').removeClass('d-none');
            $('.actions-buttons').addClass('d-none');
        },
        controlButtonsDownload : function ()
        {
            if(mediacenter.checkHaveDowloadsSelected() === true)
            {
                $('#download-zip').prop('disabled',false);
                $('#download-csv').prop('disabled',false);
                $('#download-selected').prop('disabled',false);
            }
            else
            {
                $('#download-zip').prop('disabled',true);
                $('#download-csv').prop('disabled',true);
                $('#download-selected').prop('disabled',true);
            }
        },
        downloadZip : function ()
        {
            $('#download-zip').click(function ()
            {
                var files = mediacenter.getFilesToDownload();
                mediacenter.treatFile(files[0],files[1],site_url + '/download_files/zip/' + files[2]);
            })
        },
        downloadCsv : function ()
        {
            $('#download-csv').click(function ()
            {
                var files = mediacenter.getFilesToDownload();
                mediacenter.treatFile(files[0],files[1],site_url + '/download_files/csv/' + files[2]);
            })
        },
        downloadAllSelecteds : function ()
        {
            $('#download-selected').click(function ()
            {
                var files = [];
                var actions = [];
                $('ul#files').find('input[type="checkbox"]').each(function ()
                {
                    if($(this).prop('checked')){
                        files.push($(this).val());
                        actions.push('remove');
                        var link = document.createElement('a');
                        link.style.display = 'none';
                        var descarga=$(this).closest('li').find('.link-file');
                        link.setAttribute('href', $(descarga).prop('href') );
                        link.setAttribute('download', $(descarga).html()  );
                        link.click();
                        if(mediacenter.checkDownloadCenter() === true){
                            $(this).closest('li').remove();
                        }
                    }
                })
                mediacenter.treatFile(files,actions);
                if(mediacenter.checkDownloadCenter() === true && mediacenter.checkHaveDowloads() === false) mediacenter.cleanInfoDownloads();
            })
        },
        getFilesToDownload : function ()
        {
            var filesToDownload = '';
            var files = [];
            var actions = [];
            $('ul#files').find('input[type="checkbox"]').each(function ()
            {
                if($(this).prop('checked')){
                    filesToDownload += ((filesToDownload.length > 0) ? '__' : '') + $(this).val();
                    files.push($(this).val());
                    actions.push('remove');
                    if(mediacenter.checkDownloadCenter() === true) $(this).closest('li').remove();
                }
            })

            return [files,actions,filesToDownload];
        }
    };

    $(function(){
        mediacenter.init()
    });
})(jQuery);